<template>
  <div class="coming-soon  px-6 md:px-8 lg:px-16 bg-gradient-to-r from-yellow-500 to-amber-600 text-white ">
    <div class="content">
      <h1 class="title">Coming Soon</h1>
      <p class="subtitle">User accounts are coming soon! <br> Feel free to stream our public episodes in the meantime.</p>
      <div class="countdown">
        <div v-for="(value, unit) in timeLeft" :key="unit" class="time-unit">
          <span class="number">{{ value }}</span>
          <span class="label">{{ unit }}</span>
        </div>
      </div>
      <!-- <form @submit.prevent="subscribeEmail" class="email-form">
        <input 
          v-model="email" 
          type="email" 
          placeholder="Enter your email" 
          required
        >
        <button type="submit">Notify Me</button>
      </form> -->
    </div>
    <div class="animation-container">
      <div v-for="n in 50" :key="n" class="circle"></div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, computed } from 'vue';

export default {
  name: 'ComingSoon',
  setup() {
    const email = ref('');
    const launchDate = new Date('2024-10-31T00:00:00'); // Set your launch date here
    const now = ref(new Date());

    const timeLeft = computed(() => {
      const difference = launchDate - now.value;
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / 1000 / 60) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      return {
        days,
        hours,
        minutes,
        seconds
      };
    });

    const updateTime = () => {
      now.value = new Date();
    };

    let interval;
    onMounted(() => {
      interval = setInterval(updateTime, 1000);
    });

    onUnmounted(() => {
      clearInterval(interval);
    });

    const subscribeEmail = () => {
      // Implement your email subscription logic here
      console.log('Subscribed:', email.value);
      email.value = '';
    };

    return {
      email,
      timeLeft,
      subscribeEmail
    };
  }
}
</script>

<style scoped>
.coming-soon {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: 'Arial', sans-serif;
  overflow: hidden;
  position: relative;

}

.content {
  text-align: center;
  z-index: 1;
}

.title {
  font-size: 4rem;
  margin-bottom: 1rem;
  animation: fadeInDown 1s ease-out;
}

.subtitle {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  animation: fadeInUp 1s ease-out 0.5s both;
}

.countdown {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.time-unit {
  margin: 0 1rem;
  animation: pulse 1s infinite alternate;
}

.number {
  font-size: 3rem;
  font-weight: bold;
  display: block;
}

.label {
  font-size: 1rem;
  text-transform: uppercase;
}

.email-form {
  display: flex;
  justify-content: center;
  animation: fadeInUp 1s ease-out 1s both;
}

input[type="email"] {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: none;
  border-radius: 4px 0 0 4px;
  width: 300px;
}

button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #45a049;
}

.animation-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
}

.circle {
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  animation: float 20s infinite linear;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
}

@keyframes float {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
  }
}

/* Generate random sizes and positions for circles */
.circle {
  width: 10px;
  height: 10px;
  left: 50%;
  bottom: -100px;
}

.circle:nth-child(1) { left: 10%; animation-delay: 0s; }
.circle:nth-child(2) { left: 20%; animation-delay: 2s; animation-duration: 12s; }
.circle:nth-child(3) { left: 35%; animation-delay: 4s; }
.circle:nth-child(4) { left: 50%; animation-delay: 6s; animation-duration: 18s; }
.circle:nth-child(5) { left: 65%; animation-delay: 8s; }
.circle:nth-child(6) { left: 80%; animation-delay: 10s; animation-duration: 30s; }
.circle:nth-child(7) { left: 90%; animation-delay: 12s; }
.circle:nth-child(8) { left: 25%; animation-delay: 14s; animation-duration: 25s; }
.circle:nth-child(9) { left: 75%; animation-delay: 16s; }
.circle:nth-child(10) { left: 85%; animation-delay: 18s; animation-duration: 35s; }

/* Add more nth-child rules for additional circles */
</style>
