<template>
  <div class="home">
    <!-- Banner Section -->
    <div class="banner relative h-96 bg-cover bg-center flex items-center">
      <div class="absolute inset-0 bg-black opacity-50"></div>
      <div class="container mx-auto px-4 md:px-8 lg:px-16 relative z-10 text-white">
        <h1 class="text-4xl md:text-5xl font-bold mb-4">Welcome to Julian's Liberty Crusade</h1>
        <p class="text-xl md:text-2xl mb-6">Explore thought-provoking discussions on liberty and freedom.</p>
        <button @click="goToLatestEpisode" class="bg-yellow-500 text-white px-6 py-3 rounded-md text-lg font-semibold hover:bg-yellow-600 transition duration-300">
          Listen Now
        </button>
      </div>
    </div>

    <!-- Episodes by Category -->
    <div class="container px-6 md:px-8 lg:px-16 py-8 mx-auto">
      <h2 class="text-xl text-center">Have you been wanting to hear the truth about what's happening in America? We have no special interests, we're not a news network, we're just a podcast that wants to share the truth about what's happening in America. Tune in for engaging and independent libertarian political commentary here on JLC Podcast.</h2>
      <LoadingSpinner v-if="loading" />
      <div v-else-if="error" class="text-center text-red-500">
        <p>{{ error }}</p>
      </div>
      <div v-else>
        <div v-for="(episodes, category) in nonEmptyCategories" :key="category">
          <div class="title">
            <h3 class="text-3xl py-5 font-bold">{{ category }}</h3> 
            <router-link :to="`/category/${category}`" class="text-stone-600">See More</router-link>
          </div>
          <EpisodeList :episodes="episodes.slice(0, 3)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import EpisodeList from '@/components/EpisodeList.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default {
  name: 'Home',
  components: { EpisodeList, LoadingSpinner },
  setup() {
    const categorizedEpisodes = ref({});
    const loading = ref(true);
    const error = ref(null);
    const router = useRouter();

    const nonEmptyCategories = computed(() => {
      return Object.fromEntries(
        Object.entries(categorizedEpisodes.value).filter(([_, episodes]) => episodes.length > 0)
      );
    });

    const goToLatestEpisode = () => {
      const latestEpisode = Object.values(categorizedEpisodes.value)
        .flat()
        .sort((a, b) => new Date(b.date) - new Date(a.date))[0];
      
      if (latestEpisode) {
        router.push({ name: 'PodcastEpisodeDetails', params: { id: latestEpisode.guid } });
      }
    };

    const fetchEpisodes = async (retryCount = 0) => {
      try {
        loading.value = true;
        error.value = null;
        const response = await axios.get('https://backend.chorechampion.com:16969/api/episodes');
        categorizedEpisodes.value = response.data.categorized;
        loading.value = false;
      } catch (err) {
        console.error('Failed to fetch episodes:', err);
        if (retryCount < 3) {
          setTimeout(() => fetchEpisodes(retryCount + 1), 2000);
        } else {
          error.value = 'Failed to load episodes. Please try again later.';
          loading.value = false;
        }
      }
    };

    onMounted(() => {
      fetchEpisodes();
    });

    return { nonEmptyCategories, goToLatestEpisode, loading, error };
  }
}
</script>

<style scoped>
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.banner {
  background-image: url('/public/images/flag.jpeg');
  background-size: cover;
  background-position: center;
}
</style>