<template>
  <div ref="searchBarRef" class="relative inline-block w-full lg:w-64">
    <div class="relative">
      <input
        v-model="searchQuery"
        @input="debounceSearch"
        @focus="isFocused = true"
        type="text"
        placeholder="Search episodes and articles..."
        class="w-full p-2 pr-10 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-yellow-500 text-sm"
      />
      <button
        v-if="searchQuery"
        @click="clearSearch"
        class="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
      </button>
    </div>
    <div 
      v-if="searchResults.length > 0 && isFocused" 
      class="absolute z-20 mt-1 w-full bg-white rounded-md shadow-lg overflow-hidden"
    >
      <ul class="max-h-60 overflow-auto">
        <li 
          v-for="result in searchResults" 
          :key="result.id || result.guid" 
          class="p-2 hover:bg-gray-100 cursor-pointer text-sm flex items-center" 
          @click="selectResult(result)"
        >
          <img 
            :src="getImageUrl(result)" 
            :alt="result.title" 
            class="w-12 h-12 object-cover rounded-md mr-3"
          >
          <div>
            <div class="font-semibold">{{ result.title }}</div>
            <div class="text-xs text-gray-500">
              {{ formatDate(result.date) }} | {{ result.type }}
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';

export default {
  name: 'SearchBar',
  emits: ['close'],
  setup(props, { emit }) {
    const searchQuery = ref('');
    const searchResults = ref([]);
    const allEpisodes = ref([]);
    const allBlogPosts = ref([]);
    const router = useRouter();
    const isFocused = ref(false);
    const searchBarRef = ref(null);
    const defaultCoverArt = ref('/path/to/default-cover.jpg');

    const debounceSearch = (() => {
      let timeout = null;
      return () => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          if (searchQuery.value) {
            performSearch();
          } else {
            searchResults.value = [];
          }
        }, 300);
      };
    })();

    const performSearch = () => {
      const query = searchQuery.value.toLowerCase();
      const episodeResults = allEpisodes.value
        .map(episode => ({
          ...episode,
          type: 'Episode',
          score: calculateScore(episode, query)
        }))
        .filter(episode => episode.score > 0);

      const blogResults = allBlogPosts.value
        .map(post => ({
          ...post,
          type: 'Article',
          score: calculateScore(post, query)
        }))
        .filter(post => post.score > 0);

      const combinedResults = [...episodeResults, ...blogResults]
        .sort((a, b) => b.score - a.score)
        .slice(0, 5); // Limit to top 5 results

      searchResults.value = combinedResults;
    };

    const calculateScore = (item, query) => {
      let score = 0;
      if (item.title.toLowerCase().includes(query)) score += 3;
      if (item.description && item.description.toLowerCase().includes(query)) score += 2;
      if (item.content && item.content.toLowerCase().includes(query)) score += 1;
      return score;
    };

    const clearSearch = () => {
      searchQuery.value = '';
      searchResults.value = [];
    };

    const selectResult = (result) => {
      emit('close');
      if (result.type === 'Episode') {
        router.push({ name: 'PodcastEpisodeDetails', params: { id: result.guid } });
      } else {
        router.push({ name: 'BlogPost', params: { slug: result.slug } });
      }
      clearSearch();
    };

    const handleClickOutside = (event) => {
      if (searchBarRef.value && !searchBarRef.value.contains(event.target)) {
        isFocused.value = false;
      }
    };

    const formatDate = (dateString) => {
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const getImageUrl = (result) => {
      if (result.type === 'Episode') {
        return result.image?.url || defaultCoverArt.value;
      } else {
        return `/blog_be/covers/${result.id}.jpeg`;
      }
    };

    watch(searchQuery, (newValue) => {
      if (!newValue) {
        searchResults.value = [];
      }
    });

    const fetchAllEpisodes = async () => {
      try {
        const response = await axios.get(`https://backend.chorechampion.com:16969/api/episodes`);
        allEpisodes.value = response.data.all || [];
      } catch (error) {
        console.error('Error fetching episodes:', error);
      }
    };

    const fetchAllBlogPosts = async () => {
      try {
        const response = await axios.get('/blog_be/posts.json');
        allBlogPosts.value = response.data || [];
        // Fetch content for each blog post
        for (const post of allBlogPosts.value) {
          try {
            const contentResponse = await axios.get(`/blog_be/posts/${post.markdownFile}`);
            post.content = contentResponse.data;
          } catch (error) {
            console.error(`Error fetching content for blog post ${post.id}:`, error);
          }
        }
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      }
    };

    onMounted(() => {
      fetchAllEpisodes();
      fetchAllBlogPosts();
      document.addEventListener('click', handleClickOutside);
    });

    onUnmounted(() => {
      document.removeEventListener('click', handleClickOutside);
    });

    return {
      searchQuery,
      searchResults,
      debounceSearch,
      clearSearch,
      selectResult,
      isFocused,
      searchBarRef,
      defaultCoverArt,
      formatDate,
      getImageUrl,
    };
  },
};
</script>