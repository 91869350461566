import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';
import Category from '@/views/Category.vue';
import PodcastEpisodeDetails from '@/views/PodcastEpisodeDetails.vue'
import OurMission from '@/views/OurMission.vue'
import Blog from '@/views/Blog.vue'
import BlogPost from '@/views/BlogPost.vue'
import ComingSoon from '@/views/ComingSoon.vue'
import FrequentlyAskedQuestions from '@/views/FrequentlyAskedQuestions.vue'
import ContactUs from '@/views/ContactUs.vue'
import Account from '@/views/Account.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/category/:category',
    name: 'Category',
    component: Category
  },
  {
    path: '/episode/:id',
    name: 'PodcastEpisodeDetails',
    component: PodcastEpisodeDetails
  },
  {
    path: '/mission',
    name: 'Our Mission',
    component: OurMission
  },
  {
    path: '/faqs',
    name: 'Frequently Asked Questions',
    component: FrequentlyAskedQuestions
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog
  },
  {
    path: '/blog/:slug',
    name: 'BlogPost',
    component: BlogPost
  },
  {
    path: '/comingsoon',
    name: 'ComingSoon',
    component: ComingSoon
  },

  {
    path: '/contact',
    name: 'ContactUs',
    component: ContactUs
  },
  {
    path: '/account',
    name: 'Account',
    component: Account
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;