<template>
  <div class="faq-page container mx-auto px-4 py-8">
    <h1 class="text-4xl font-bold mb-8 text-center">Frequently Asked Questions</h1>
    <div v-if="loading" class="text-center">
      <LoadingSpinner />
    </div>
    <div v-else-if="error" class="text-center text-red-500">
      {{ error }}
    </div>
    <div v-else class="max-w-3xl mx-auto">
      <div v-for="(item, index) in faqItems" :key="index" class="mb-4">
        <div 
          @click="toggleItem(index)"
          class="bg-white p-4 rounded-md shadow-md cursor-pointer flex justify-between items-center hover:bg-gray-50 transition-colors duration-200"
        >
          <h2 class="text-xl font-semibold">{{ item.question }}</h2>
          <svg 
            :class="{'rotate-180': openItems[index]}"
            class="w-6 h-6 transition-transform duration-200" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24" 
            xmlns="http://www.w3.org/2000/svg"
          >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
          </svg>
        </div>
        <div 
          v-show="openItems[index]"
          class="bg-gray-50 p-4 rounded-b-md shadow-md mt-1 transition-all duration-200"
        >
          <div class="text-gray-700" v-html="item.answer"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useHead } from '@vueuse/head'

import axios from 'axios';
import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default {
  name: 'FrequentlyAskedQuestions',
  components: { LoadingSpinner },
  setup() {
    const faqItems = ref([]);
    const openItems = ref({});
    const loading = ref(true);
    const error = ref(null);

    useHead({
      title: 'FAQs | Julian\'s Liberty Crusade',
      meta: [
        { name: 'description', content: 'Here are answers to some of JLC’s most frequently asked questions. Learn more about JLC: our history, values, aspirations, and goals.' },
        { name: 'keywords', content: 'civil liberties podcast, libertarian economic policies, libertarian political views, personal freedom podcast, libertarian podcasts, freedom, election news, mainstream media, libertarian, politics, news, libertarianism, political commentary, JLC Podcast, other political parties, where do i stand politically' },
      ],
    })
    const fetchFAQ = async () => {
      try {
        const response = await axios.get('/faq.json');
        faqItems.value = response.data;
        faqItems.value.forEach((_, index) => {
          openItems.value[index] = false;
        });
        loading.value = false;
      } catch (err) {
        console.error('Failed to fetch FAQ:', err);
        error.value = 'Failed to load FAQ. Please try again later.';
        loading.value = false;
      }
    };

    const toggleItem = (index) => {
      openItems.value[index] = !openItems.value[index];
    };

    onMounted(() => {
      fetchFAQ();
    });

    return { faqItems, openItems, toggleItem, loading, error };
  }
}
</script>

<style scoped>
/* Add any additional styles here if needed */
</style>
