''<template>
    <div>
        <header class="bg-gradient-to-r from-yellow-500 to-amber-600  text-white py-16">
            <div class="container mx-auto px-6 md:px-8 lg:px-16">
                <h1 class="text-4xl font-bold mb-4">Our Mission</h1>
                <p class="text-xl">Discover what drives us and shapes our goals.</p>
            </div>
        </header>
        <div class="container px-6 md:px-8 lg:px-16 py-8 mx-auto">
            <div v-if="loading" class="text-center">
                <p>Loading mission content...</p>
            </div>
            <div v-else class="prose lg:prose-xl mx-auto" v-html="parsedMissionContent"></div>
        </div>
        <div class="relative w-full bg-cover bg-center py-16" style="background-image: url('/images/flag.jpeg');">
            <div class="absolute inset-0 bg-black opacity-50"></div>
            <div class="z-10  relative container mx-auto px-6 md:px-8 lg:px-16 text-center">
                <h1 class="text-4xl font-bold mb-4 text-white">Join Free Today</h1>
                <p class="text-xl mb-4 text-white">Sign up for free today and hear the stories they don't want you to hear.</p>
                <button class="bg-yellow-500 text-white px-8 py-3 rounded-2xl hover:bg-yellow-600 border-2 border-yellow-500">Sign Up</button>
            </div>
        </div>
    </div>
</template>

<script>
import { marked } from 'marked';
import { useHead } from '@vueuse/head'
import axios from 'axios';

export default {
    name: 'OurMission',
    data() {
        return {
            missionContent: '',
            loading: true,
        };
    },
    computed: {
        parsedMissionContent() {
            return marked(this.missionContent);
        }
    },
    async mounted() {
        useHead( {
        title: "Our Mission | Julian's Liberty Crusade",
        meta: [
            { name: 'description', content: 'Learn more about Julian’s Liberty Crusade, the news podcast that brings you trustworthy, authentic, and uninfluenced news.' }
        ]})
        try {
            const response = await axios.get('/content/mission.md');
            this.missionContent = response.data;
        } catch (error) {
            console.error('Failed to fetch mission content:', error);
            this.missionContent = 'Failed to load mission content. Please try again later.';
        } finally {
            this.loading = false;
        }
    }
}
</script>
