<template>
  <div class="category">
    <!-- Header Section -->
    <div class="header relative h-96 bg-cover bg-center flex items-center p-5"
      :style="{ backgroundImage: `url(${categoryData.backgroundImage})` }">
      <div class="absolute inset-0 bg-black opacity-50"></div>
      <div class="container mx-auto px-4 md:px-8 lg:px-16 relative z-10 text-white">
        <h1 class="text-4xl md:text-5xl font-bold mb-4">{{ $route.params.category }}</h1>
        <p class="text-xl md:text-2xl mb-6">{{ categoryData.description }}</p>
        <button v-if="latestEpisode" @click="goToLatestEpisode"
          class="bg-yellow-500 text-white px-6 py-3 rounded-md text-lg font-semibold hover:bg-yellow-600 transition duration-300">
          Listen to Latest Episode
        </button>
      </div>
    </div>

    <div class="">
      <div v-if="loading" class="text-center">
        <LoadingSpinner />
      </div>
      <div v-else-if="error" class="text-center text-red-500">
        {{ error }}
      </div>
      <div v-else>
        <!-- First 3 episodes -->
        <EpisodeList :episodes="episodes.slice(0, 3)" v-if="episodes.length > 0"
          :style="`${episodes.length < 3 ? 'padding-bottom: 30px' : ''}`" />

        <!-- Banner -->
        <div v-if="episodes.length > 3" class="my-12 relative h-64 bg-cover bg-center flex items-center"
          :style="{ backgroundImage: `url(${categoryData.bannerImage || categoryData.backgroundImage})` }">
          <div class="absolute inset-0 bg-black opacity-50"></div>
          <div class="container mx-auto px-4 md:px-8 lg:px-16 relative z-10 text-white text-center">
            <h2 class="text-3xl md:text-4xl font-bold mb-4">{{ categoryData.bannerTitle || 'More Episodes' }}</h2>
            <p class="text-lg md:text-xl">{{ categoryData.bannerDescription || "Discover more great content in this category" }}</p>
          </div>
        </div>

        <!-- Remaining episodes -->
        <EpisodeList :episodes="episodes.slice(3)" v-if="episodes.length > 3" />

        <!-- Recommended Episodes -->
        <div :class="`${$root.$data.user == null ? 'mb-0' : 'mb-6'}`">
          <div class="container mx-auto">
            <h2 class="text-2xl font-bold text-center">Recommended Episodes</h2>
            <EpisodeList class="recommended-episodes" :episodes="recommendedEpisodes" />
          </div>
        </div>

        <!-- New section for bottom message -->
        <div v-if="!$root.$data.user" class="py-8 bg-gray-100 text-center">
          <div class="container mx-auto px-4 md:px-8 lg:px-16 space-y-4">
            <h2 class="text-2xl font-bold mb-4">You've made it to the bottom!</h2>
            <p class="text-lg">
              While I gotcha here, it doesn't look like you've signed up yet. 
              Sign up FREE for exclusive content.
            </p>
            <div class="space-x-4">
              <button @click="signUp" class="bg-yellow-500 text-white px-6 py-2 rounded-md text-lg font-semibold hover:bg-yellow-600 transition duration-300">
                Sign Up
              </button>
              <button @click="logIn" class="bg-gray-500 text-white px-6 py-2 rounded-md text-lg font-semibold hover:bg-gray-600 transition duration-300">
                Log In
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';
import EpisodeList from '@/components/EpisodeList.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { useHead } from '@vueuse/head';
export default {
  name: 'Category',
  components: { EpisodeList, LoadingSpinner },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const episodes = ref([]);
    const categoryData = ref({});
    const loading = ref(true);
    const error = ref(null);
    const allEpisodes = ref([]);
    const categorizedEpisodes = ref([]);
    const recommendedEpisodes = ref([]);

    const latestEpisode = computed(() => {
      return episodes.value.length > 0 ? episodes.value[0] : null;
    });

    const fetchCategoryData = async () => {
      try {
        const response = await axios.get('/categoryData.json');
        categoryData.value = response.data[route.params.category] || {};
      } catch (error) {
        console.error('Failed to fetch category data:', error);
      }
    };

    const fetchEpisodes = async (retryCount = 0) => {
      try {
        loading.value = true;
        error.value = null;
        const response = await axios.get('https://backend.chorechampion.com:16969/api/episodes');
        episodes.value = response.data.categorized[route.params.category] || [];
        loading.value = false;
      } catch (err) {
        console.error('Failed to fetch episodes:', err);
        if (retryCount < 3) {
          setTimeout(() => fetchEpisodes(retryCount + 1), 2000);
        } else {
          error.value = 'Failed to load episodes. Please try again later.';
          loading.value = false;
        }
      }
    };

    const goToLatestEpisode = () => {
      if (latestEpisode.value) {
        router.push({ name: 'PodcastEpisodeDetails', params: { id: latestEpisode.value.guid } });
      }
    };

    const signUp = () => {
      // Implement sign up logic or navigation
      netlifyIdentity.open("signup");
    };

    const logIn = () => {
      // Implement login logic or navigation
      netlifyIdentity.open("login");
    };

    const fetchAllEpisodes = async () => {
      try {
        const response = await axios.get('https://backend.chorechampion.com:16969/api/episodes');
        allEpisodes.value = response.data.all || [];
        categorizedEpisodes.value = response.data.categorized || [];
        getRecommendedEpisodes();
        useHead({
            title: `${route.params.category} | Julian's Liberty Crusade`,
            meta: [
              { name: 'description', content: `${categoryData.value.bannerDescription || categoryData.value.description}` },
            ]
          });
      } catch (error) {
        console.error('Failed to fetch all episodes:', error);
      }
    };

    const getRecommendedEpisodes = () => {
      const currentCategory = route.params.category;
      const categories = ["Shorts", "Deep Dive", "Special Guest"];
      const otherCategories = categories.filter(cat => cat !== currentCategory);
      
      let otherEpisodes = []
      otherCategories.forEach(cat => {
        otherEpisodes = otherEpisodes.concat(categorizedEpisodes.value[cat])
      })
      recommendedEpisodes.value = otherEpisodes
        .sort(() => 0.5 - Math.random())
        .slice(0, 4 );
    };

    onMounted(() => {
      fetchCategoryData();
      fetchEpisodes();
      fetchAllEpisodes();
      
 
    });

    return { 
      episodes, 
      categoryData, 
      latestEpisode, 
      goToLatestEpisode, 
      loading, 
      error,
      signUp,
      logIn,
      categorizedEpisodes,
      recommendedEpisodes
    };
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {

.recommended-episodes {
  & > :not(:first-child) {
display: none;
  }
}
}

.header,
.banner {
  background-size: cover;
  background-position: center;
}

@media (max-width: 768px) {

  .grid-cols-3 {
    grid-template-columns: 1fr;
  }
}
</style>