<template>
  <div id="app" class="min-h-screen w-full flex flex-col bg-gray-100">
    <NavBar />
    <main class="flex-grow">
      <transition name="fade" mode="out-in">
        <router-view :key="$route.fullPath"></router-view>
      </transition>
    </main>
    <Footer />
  </div>
</template>

<script>
import { ref } from 'vue'
import Footer from '@/components/Footer.vue'
import NavBar from '@/components/NavBar.vue'
export default {
  name: 'App',
  components: {
    Footer,
    NavBar
  },
  mounted() {
    netlifyIdentity.on('init', user => this.$data.user = user);
    netlifyIdentity.on('login', user => { this.$data.user = user; netlifyIdentity.close(); window.location.reload(); });
    netlifyIdentity.on('logout', () => { this.$data.user = null; window.location.reload(); });
    this.$data.user = netlifyIdentity.currentUser();
  },
  data() {
    return {
      user: null
    }
  }
}
</script>

<style>
@media (min-width: 936px) {
  header {
    grid-template-columns: auto 1fr auto;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
