<template>
  <div v-if="post" class="bg-gray-100 min-h-screen py-6">
    <div class="container mx-auto px-4 sm:px-6 lg:px-8">
      <article class="bg-white shadow-lg rounded-2xl overflow-hidden mb-8">
        <img :src="`/blog_be/covers/${post.id}.jpeg`" :alt="post.title" class="w-full h-64 object-cover">
        <div class="p-6 sm:p-8 relative">
          <h1 class="text-3xl sm:text-4xl font-bold text-gray-900 mb-4">{{ post.title }}</h1>
          <div class="flex justify-between items-center mb-6">
            <p class="text-gray-600">Published {{ formattedDate }} by {{ post.author }}</p>
            <ShareButtons :url="currentPageUrl" :title="post.title" />
          </div>
          <div v-if="canViewPost" v-html="renderedContent" class="prose max-w-none"></div>
          <div v-else class="relative h-screen md:h-full">
            <span class="text-gray-600 mb-8 text-lg">{{ post.description }}<br><br></span>
            <div class="blur-sm">
              <div v-html="fakeContent" class="prose max-w-none"></div>
            </div>
            <div class="absolute inset-0 flex items-center justify-center">
              <div class="bg-white p-8 rounded-lg shadow-lg text-center paywall-label">
                <p v-if="(Date.now() < (new Date(post.date)))" class="text-lg text-gray-700 mb-6">This content not released yet, check back soon.</p>
                <p v-else class="text-lg text-gray-700 mb-6">This content is is for logged in users only. <br>Login or signup <strong>for free</strong> to view this content.</p>
                <button @click="$router.go(-1)" v-if="(Date.now() < (new Date(post.date)))" class="bg-yellow-500 text-white px-6 py-3 rounded-md text-lg font-semibold hover:bg-yellow-600 transition duration-300">
                  Back to Blog
                </button>
                <button
                  v-else
                  @click="login"
                  class="bg-yellow-500 text-white px-6 py-3 rounded-md text-lg font-semibold hover:bg-yellow-600 transition duration-300"
                >
                  Log In
                </button>
              </div>
            </div>
          </div>
        </div>
      </article>
      
      <!-- Recommended Articles -->
      <div v-if="recommendedPosts.length" class="bg-white shadow-lg rounded-2xl overflow-hidden p-6 sm:p-8">
        <h2 class="text-2xl font-bold text-gray-900 mb-4">Recommended Articles</h2>
        <div  class="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div v-for="recommendedPost in recommendedPosts" :key="recommendedPost.id" class="bg-gray-50 rounded-lg overflow-hidden shadow-md">
            <router-link :to="{ name: 'BlogPost', params: { slug: recommendedPost.slug } }">
              <img :src="`/blog_be/covers/${recommendedPost.id}.jpeg`" :alt="recommendedPost.title" class="w-full h-48 object-cover">
              <div class="p-4">
                <h3 class="text-lg font-semibold mb-2">{{ recommendedPost.title }}</h3>
                <p class="text-sm text-gray-600 mb-4">{{ recommendedPost.description }}</p>
                <p class="text-yellow-600 hover:text-yellow-700 font-medium">Read More</p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="flex justify-center items-center h-screen bg-gray-100">
    <div class="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-yellow-500"></div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useHead } from '@vueuse/head'
import { marked } from 'marked';
import axios from 'axios';
import ShareButtons from '@/components/ShareButtons.vue';

export default {
  name: 'BlogPost',
  components: { ShareButtons },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const post = ref(null);
    const content = ref('');
    const allPosts = ref([]);
    const recommendedPosts = ref([]);
    const isLoggedIn = ref(netlifyIdentity.currentUser() != null);

    const canViewPost = computed(() => {
      if (!post.value) return false;
      const postDate = new Date(post.value.date);
      const now = new Date();
      if (postDate > now) return false; // Future post
      return post.value.locked ? isLoggedIn.value : (!isNewestPublishedPost.value || isLoggedIn.value);
    });

    const newestPublishedPost = computed(() => {
      const now = new Date();
      return allPosts.value
        .filter(p => new Date(p.date) <= now)
        .sort((a, b) => new Date(b.date) - new Date(a.date))[0];
    });

    const isNewestPublishedPost = computed(() => {
      if (!post.value || !newestPublishedPost.value) return false;
      return newestPublishedPost.value.id === post.value.id;
    });

    const formattedDate = computed(() => {
      if (post.value) {
        return new Date(post.value.date).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        });
      }
      return '';
    });

    const renderedContent = computed(() => {
      return marked(content.value);
    });

    const currentPageUrl = computed(() => {
      return `${window.location.origin}${route.fullPath}`;
    });

    const fakeContent = computed(() => {
      return marked(`
## Welcome to Julian's Liberty Crusade: This is a paywalled article.

Login or signup to view this content, paywalled content doesn't load into the page until you login.

${post.value.description}

## Don't forget to login so you can view the rest of the article.

Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.

### In-Depth Articles  
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.

### Engaging Podcast Episodes  
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.

### Community Discussions  
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.

### Resources for Activism  
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.

---

Join us on our journey toward personal freedom and empowerment. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
## Welcome to Julian's Liberty Crusade

Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.;

## This article is free but you need to login to view it.
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`);
    });

    const fetchPosts = async () => {
      try {
        const { data } = await axios.get('/blog_be/posts.json');
        allPosts.value = data;
        post.value = data.find(p => p.slug === route.params.slug);

        if (post.value) {
          if (canViewPost.value) {
            const { data: markdownContent } = await axios.get(`/blog_be/posts/${post.value.markdownFile}`);
            content.value = markdownContent;
          }
          
          useHead({
            title: `${post.value.title} | Julian's Liberty Crusade`,
            meta: [
              { name: 'description', content: `${post.value.description}` },
              { name: 'keywords', content: `${post.value.keywords}` }
            ]
          });

          // Get recommended posts
          const now = new Date();
          const eligiblePosts = allPosts.value.filter(p => 
            p.id !== post.value.id && new Date(p.date) <= now
          );
          recommendedPosts.value = eligiblePosts
            .sort(() => 0.5 - Math.random())
            .slice(0, 3);
        }
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      }
    };

    const login = () => {
      // Implement your login logic here
      // For example, you might redirect to a login page:
      netlifyIdentity.open('login');
      netlifyIdentity.on('login', () => window.location.reload())
    };

    onMounted(() => {
      fetchPosts();
    });

    return {
      post,
      content,
      formattedDate,
      renderedContent,
      currentPageUrl,
      recommendedPosts,
      canViewPost,
      login,
      fakeContent
    };
  }
}
</script>

<style type="text/tailwindcss">
.paywall-label {
  top: 80px;
  position: absolute;}
.prose {
  @apply text-gray-800 leading-relaxed;
}

.prose h2 {
  @apply text-2xl font-semibold mt-8 mb-4;
}

.prose h3 {
  @apply text-xl font-semibold mt-6 mb-3;
}

.prose p {
  @apply mb-4;
}

.prose ul, .prose ol {
  @apply mb-4 pl-6;
}

.prose li {
  list-style-type: disc;
  @apply mb-2;
}

.prose a {
  @apply text-yellow-600 hover:text-yellow-700 underline;
}

.prose blockquote {
  @apply border-l-4 border-yellow-500 pl-4 italic my-4;
}

.prose code {
  @apply bg-gray-100 rounded px-1 py-0.5 font-mono text-sm;
}

.prose pre {
  @apply bg-gray-100 rounded p-4 overflow-x-auto my-4;
}

.prose img {
  @apply rounded-2xl shadow-md my-4;
}

.blur-sm {
  filter: blur(6px);
}
</style>