<template>
  <div v-if="episode" class="podcast-episode-details p-4 md:p-8 lg:p-16">
    <h1 class="text-2xl font-bold">{{ episode.title }}</h1>
    <p class="text-sm text-gray-600 mt-2 mb-4">Premired {{ formatDate(episode.pubDate) }}</p>
    <div class="flex flex-col md:flex-row md:gap-8">
      <div class="md:w-1/3">
        <img :src="episode.image.url" :alt="episode.title" class="w-full rounded-2xl transition-shadow duration-300 overflow-hidden shadow-lg  hover:shadow-xl transition-shadow duration-300 overflow-hidden">
        <p class="mt-2 text-sm text-gray-600">{{ episode.imageDescription }}</p>
      </div>
      <div class="md:w-2/3">
        <p class="text-lg font-semibold mb-2">{{ episode.category }}</p>
        <div class="audio-player bg-yellow-500 p-4 rounded-2xl shadow-md">
          <div class="flex items-center justify-center mb-2 space-x-4">
            <button @click="rewind" class="control-btn">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-white hover:text-yellow-200 focus:outline-none">
                <path d="M9.195 18.44c1.25.713 2.805-.19 2.805-1.629v-2.34l6.945 3.968c1.25.714 2.805-.188 2.805-1.628V8.688c0-1.44-1.555-2.342-2.805-1.628L12 11.03v-2.34c0-1.44-1.555-2.343-2.805-1.629l-7.108 4.062c-1.26.72-1.26 2.536 0 3.256l7.108 4.061z" />
              </svg>
            </button>
            <button @click="togglePlay" class="play-pause-btn bg-white text-yellow-500 rounded-full w-10 h-10 flex items-center justify-center focus:outline-none hover:text-yellow-600">
              <svg v-if="!isPlaying" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                <path fill-rule="evenodd" d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z" clip-rule="evenodd" />
              </svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                <path fill-rule="evenodd" d="M6.75 5.25a.75.75 0 01.75-.75H9a.75.75 0 01.75.75v13.5a.75.75 0 01-.75.75H7.5a.75.75 0 01-.75-.75V5.25zm7.5 0A.75.75 0 0115 4.5h1.5a.75.75 0 01.75.75v13.5a.75.75 0 01-.75.75H15a.75.75 0 01-.75-.75V5.25z" clip-rule="evenodd" />
              </svg>
            </button>
            <button @click="fastForward" class="control-btn">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-white hover:text-yellow-200 focus:outline-none">
                <path d="M5.055 7.06c-1.25-.714-2.805.189-2.805 1.628v8.123c0 1.44 1.555 2.342 2.805 1.628L12 14.471v2.34c0 1.44 1.555 2.342 2.805 1.628l7.108-4.061c1.26-.72 1.26-2.536 0-3.256L14.805 7.06C13.555 6.346 12 7.25 12 8.688v2.34L5.055 7.06z" />
              </svg>
            </button>
          </div>
          <div class="text-sm text-white mb-2 text-center">
            {{ formatTime(currentTime) }} / {{ formatTime(duration) }}
          </div>
          <div 
            class="progress-bar bg-yellow-600 h-2 rounded-full overflow-hidden cursor-pointer"
            @click="seek"
            @mousedown="startScrubbing"
            @mousemove="scrub"
            @mouseup="stopScrubbing"
            @mouseleave="stopScrubbing"
            ref="progressBar"
          >
            <div class="progress bg-white h-full" :style="{ width: `${progress}%` }"></div>
          </div>
        </div>
        <h1 class="text-xl font-bold mt-4">Description</h1>
        <p class="mt-4 text-gray-700">{{ episode.description }}</p>
      </div>
    </div>

    <!-- Recommended Episodes Section -->
    <div class="mt-12">
      <h2 class="text-2xl font-bold mb-4">Recommended Episodes</h2>
      <div v-if="recommendedEpisodes.length > 0" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <router-link :to="{ name: 'PodcastEpisodeDetails', params: { id: rec.guid } }" tag="div" v-for="rec in recommendedEpisodes" :key="rec.guid" class="bg-white rounded-2xl shadow-md hover:shadow-lg transition-shadow duration-300 overflow-hidden">
          <img :src="rec.image.url" :alt="rec.title" class="w-full h-48 object-cover">
          <div class="p-4">
            <h3 class="font-bold text-lg mb-2">{{ rec.title }}</h3>
            <p class="text-gray-600 text-sm mb-2">{{ formatDate(rec.pubDate) }}</p>
          </div>
        </router-link>
        </div>
      <p v-else class="text-gray-600">No recommended episodes available.</p>
    </div>
  </div>
  <div v-else class="p-4 md:p-8 lg:p-16">
    <LoadingSpinner />
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted, onUnmounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';
import { Howl } from 'howler';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { useHead } from '@vueuse/head';
export default defineComponent({
  name: 'PodcastEpisodeDetails',
  components: { LoadingSpinner },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const episode = ref(null);
    const allEpisodes = ref([]);
    const sound = ref(null);
    const isPlaying = ref(false);
    const currentTime = ref(0);
    const duration = ref(0);
    const progress = ref(0);
    const progressBar = ref(null);
    const isScrubbing = ref(false);

    const recommendedEpisodes = computed(() => {
      if (!episode.value || !allEpisodes.value.length) return [];
      
      return allEpisodes.value
        .filter(ep => ep.category === episode.value.category && ep.guid !== episode.value.guid)
        .sort((a, b) => new Date(b.pubDate) - new Date(a.pubDate))
        .slice(0, 3);
    });

    const fetchEpisodeDetails = async () => {
      try {
        const response = await axios.get('https://backend.chorechampion.com:16969/api/episodes');
        allEpisodes.value = response.data.all;
        const foundEpisode = allEpisodes.value.find((ep) => ep.guid === route.params.id);
        if (foundEpisode) {
          episode.value = foundEpisode;
          initializeAudio();
          useHead({
            title: `${episode.value.title} | Julian's Liberty Crusade`,
            meta: [
              { name: 'description', content: episode.value.description },
              { name: 'keywords', content: "Julians Liberty Crusade, JLC Podcast, podcast episodes on freedom, podcast episodes on liberty, podcast episodes on freedom of speech, podcast episodes on libertarianism, podcast episodes on politics, podcast episodes on libertarian economics, podcast episodes on freedom of speech, liberal podcast episodes, conservative podcast episodes, libertarian podcast episodes, libertarian political commentary, " + episode.value.title },
            ],
          });
        } else {
          console.error('Episode not found');
        }
      } catch (error) {
        console.error('Error fetching episode details:', error);
        if (error.toString().includes("Network Error")) {
          console.log("Network Error: Reloading page.")
          window.location.reload()
        }
      }
    };

    const initializeAudio = () => {
      if (sound.value) {
        sound.value.unload();
      }
      sound.value = new Howl({
        src: [episode.value.enclosures[0].url],
        html5: true,
        onplay: () => {
          isPlaying.value = true;
          requestAnimationFrame(step);
        },
        onpause: () => {
          isPlaying.value = false;
        },
        onend: () => {
          isPlaying.value = false;
        },
        onload: () => {
          duration.value = sound.value.duration();
        },
      });
    };

    const togglePlay = () => {
      if (!sound.value) return;
      if (isPlaying.value) {
        sound.value.pause();
      } else {
        sound.value.play();
      }
    };

    const step = () => {
      if (sound.value && isPlaying.value) {
        currentTime.value = sound.value.seek();
        progress.value = (currentTime.value / duration.value) * 100;
        requestAnimationFrame(step);
      }
    };

    const formatTime = (time) => {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    const rewind = () => {
      if (sound.value) {
        const newTime = Math.max(sound.value.seek() - 10, 0);
        sound.value.seek(newTime);
      }
    };

    const fastForward = () => {
      if (sound.value) {
        const newTime = Math.min(sound.value.seek() + 10, duration.value);
        sound.value.seek(newTime);
      }
    };

    const seek = (event) => {
      if (sound.value && progressBar.value) {
        const percent = event.offsetX / progressBar.value.offsetWidth;
        const newTime = percent * duration.value;
        sound.value.seek(newTime);
        currentTime.value = newTime;
      }
    };

    const startScrubbing = () => {
      isScrubbing.value = true;
    };

    const stopScrubbing = () => {
      isScrubbing.value = false;
    };

    const scrub = (event) => {
      if (isScrubbing.value && sound.value && progressBar.value) {
        const percent = event.offsetX / progressBar.value.offsetWidth;
        const newTime = percent * duration.value;
        sound.value.seek(newTime);
        currentTime.value = newTime;
        progress.value = percent * 100;
      }
    };

    const formatDate = (dateString) => {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    };

    onMounted(fetchEpisodeDetails);

    onUnmounted(() => {
      if (sound.value) {
        sound.value.unload();
      }
    });

    watch(() => route.params.id, fetchEpisodeDetails);

    return {
      episode,
      recommendedEpisodes,
      isPlaying,
      currentTime,
      duration,
      progress,
      progressBar,
      togglePlay,
      formatTime,
      rewind,
      fastForward,
      seek,
      startScrubbing,
      stopScrubbing,
      scrub,
      formatDate,
    };
  }
});
</script>
