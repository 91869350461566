<template>
  <div class="bg-white rounded-lg shadow-md overflow-hidden relative">
    <router-link :to="{ name: 'BlogPost', params: { slug: post.slug } }">
      <img :src="`/blog_be/covers/${post.id}.jpeg`" :alt="post.title" class="w-full h-48 object-cover">
      <div class="p-6">
        <h2 class="text-xl font-semibold mb-2">{{ post.title }}</h2>
        <p class="text-gray-600 mb-4">{{ formatDate(post.date) }}</p>
        <p class="text-gray-700">{{ post.description.substring(0, 200) }}...</p>
      </div>
      <!-- Is Most Recent-->
      <div v-if="isLocked && $root.$data.user == null" class="absolute top-2 right-2 bg-yellow-500 rounded-full p-2">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
        </svg>
      </div>
      <!-- Is Locked-->
      <div v-else-if="post.locked && $root.$data.user == null" class="absolute top-2 right-2 bg-yellow-500 rounded-full p-2">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
        </svg>
      </div>
      <!-- Is Not Released Yet-->
      <div v-else-if="(new Date(post.date) > new Date())" class="absolute top-2 right-2 bg-yellow-500 rounded-full p-2">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </div>

    </router-link>
  </div>
</template>

<script>
export default {
  name: 'BlogPostItem',
  props: {
    post: {
      type: Object,
      required: true
    },
    isNewest: {
      type: Boolean,
      default: false
    },
    isLocked: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const formatDate = (dateString) => {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString('en-US', options);
    };

    return {
      formatDate
    };
  }
}
</script>
