<template>
  <header class="bg-white shadow-md p-4 grid gap-6 items-center">
    <div class="flex justify-between items-center">
      <router-link to="/" @click="closeMenu">
        <h1 class="bg-yellow-500 font-bold px-2.5 rounded-2xl text-3xl text-white w-30">
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="370.000000pt" height="193.000000pt"
            viewBox="0 0 370.000000 193.000000" preserveAspectRatio="xMidYMid meet" class="h-10 w-full">
            <g transform="translate(0.000000,193.000000) scale(0.050000,-0.050000)" fill="#ffffff" stroke="none">
              <path d="M5690 2936 c-504 -96 -890 -651 -811 -1167 124 -808 1108 -1173 1699
-632 l102 93 -90 76 -90 77 -84 -71 c-522 -448 -1342 -26 -1290 663 48 631
778 950 1266 553 129 -106 108 -105 209 -11 l83 76 -106 93 c-260 228 -561
313 -888 250z" />
              <path d="M2261 2466 l3 -476 -422 1 c-232 1 -422 -2 -422 -5 0 -7 368 -501
515 -692 112 -145 101 -160 -119 -160 -303 -1 -448 108 -475 356 l-11 100
-138 6 -137 6 13 -103 c109 -816 1321 -836 1449 -24 21 128 24 125 -130 125
l-127 0 0 -65 c0 -163 -57 -257 -102 -170 -13 25 -81 119 -152 210 l-128 165
326 0 326 0 -4 595 -5 595 -131 6 -132 6 3 -476z" />
              <path d="M2980 1920 l0 -1020 841 0 841 0 -6 122 -6 123 -705 -4 -705 -3 0
901 0 901 -130 0 -130 0 0 -1020z" />
            </g>
          </svg>
        </h1>
      </router-link>
      <button @click="toggleMenu" class="max-[935px]:block hidden">
        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
        </svg>
      </button>
    </div>
    <nav :class="{ 'hidden': !isMenuOpen, 'flex': isMenuOpen }"
      class="max-[935px]:flex-col min-[936px]:flex flex-row color-zinc-800">
      <router-link to="/" @click="closeMenu"
        class="mb-2 min-[936px]:mb-0 min-[936px]:mr-4 hover:bg-zinc-200 rounded-md p-2">Home</router-link>

      <!-- Episodes dropdown for mobile -->
      <div class="max-[935px]:block min-[936px]:hidden">
        <button @click="toggleEpisodesMenu"
          class="w-full text-left mb-2 hover:bg-zinc-200 rounded-md p-2 flex items-center justify-between">
          Episodes
          <svg :class="{ 'rotate-180': isEpisodesMenuOpen }" class="w-4 h-4 ml-1 transition-transform" fill="none"
            stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
          </svg>
        </button>
        <div v-show="isEpisodesMenuOpen" class="pl-4">
          <router-link to="/category/Deep Dive" @click="closeMenu"
            class="block mb-2 hover:bg-zinc-200 rounded-md p-2">Deep Dive</router-link>
          <router-link to="/category/Special Guest" @click="closeMenu"
            class="block mb-2 hover:bg-zinc-200 rounded-md p-2">Special Guest</router-link>
          <router-link to="/category/Shorts" @click="closeMenu"
            class="block mb-2 hover:bg-zinc-200 rounded-md p-2">Shorts</router-link>
        </div>
      </div>

      <!-- Episodes dropdown for desktop -->
      <div class="max-[935px]:hidden min-[936px]:block relative group">
        <button @click="toggleEpisodesMenu"
          class="mb-2 min-[936px]:mb-0 min-[936px]:mr-4 hover:bg-zinc-200 rounded-md p-2 flex items-center">
          Episodes
          <svg class="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
          </svg>
        </button>
        <div v-show="isEpisodesMenuOpen"
          class="z-10 absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div class="py-1">
            <router-link to="/category/Deep Dive" @click="closeMenu"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Deep Dive</router-link>
            <router-link to="/category/Special Guest" @click="closeMenu"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Special Guest</router-link>
            <router-link to="/category/Shorts" @click="closeMenu"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Shorts</router-link>
          </div>
        </div>
      </div>

      <router-link to="/mission" @click="closeMenu"
        class="mb-2 min-[936px]:mb-0 min-[936px]:mr-4 hover:bg-zinc-200 rounded-md p-2">Our Mission</router-link>
      <router-link to="/blog" @click="closeMenu"
        class="mb-2 min-[936px]:mb-0 min-[936px]:mr-4 hover:bg-zinc-200 rounded-md p-2">Blog</router-link>
      <router-link to="/faqs" @click="closeMenu"
        class="mb-2 min-[936px]:mb-0 min-[936px]:mr-4 hover:bg-zinc-200 rounded-md p-2">FAQ</router-link>
      <SearchBar class="max-[936px]:mb-2 mb-0" @close="closeMenu" />

      <!-- Sign In and Sign Up buttons for mobile -->
      <div class="max-[935px]:flex flex-col min-[936px]:hidden text-center" v-if="$root.$data.user == null">
        <button @click="login" class="text-black px-4 py-2 rounded-md mb-2 hover:bg-zinc-200">Sign In</button>
        <button @click="signup" class="block bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-yellow-600">Sign
          Up</button>
      </div>
      <span v-else @click="logout"
        class="min-[936px]:hidden bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-yellow-600 text-center flex items-center justify-center">

        <span class="mr-2">Logout</span>
        <svg class="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path>
        </svg>
      </span>
    </nav>

    <!-- Sign In and Sign Up buttons for desktop -->
    <div class="buttons grid grid-cols-2 justify-end gap-4 max-[935px]:hidden" v-if="$root.$data.user == null">
      <button @click="login" class="text-black px-4 py-2 rounded-md hover:bg-zinc-200">Sign In</button>
      <button @click="signup" class="bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-yellow-600">Sign Up</button>
    </div>
    <div class="max-[935px]:hidden" v-else>
      <button @click="logout" class="text-black px-4 py-2 rounded-md hover:bg-zinc-200"><span class="mr-1">Logout</span>
        <svg class="w-5 h-5 inline-block" fill="none" stroke="currentColor" viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path>
        </svg></button>
    </div>
  </header>
</template>

<script>
import { ref, reactive } from 'vue';
import SearchBar from '@/components/SearchBar.vue';

export default {
  name: 'HeaderNavBar',
  components: {
    SearchBar
  },
  setup() {
    const isMenuOpen = ref(false)
    const isEpisodesMenuOpen = ref(false)

    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value
      if (!isMenuOpen.value) {
        isEpisodesMenuOpen.value = false
      }
    }

    const toggleEpisodesMenu = () => {
      isEpisodesMenuOpen.value = !isEpisodesMenuOpen.value
    }

    const closeMenu = () => {
      isMenuOpen.value = false
      isEpisodesMenuOpen.value = false
    }
    const login = () => {
      netlifyIdentity.open('login')
      closeMenu()
    }

    const signup = () => {
      netlifyIdentity.open('signup')
      closeMenu()
    }
    const logout = () => {
      netlifyIdentity.logout();
      closeMenu()
    }
    return {
      isMenuOpen,
      isEpisodesMenuOpen,
      toggleMenu,
      toggleEpisodesMenu,
      closeMenu,
      login,
      signup,
      logout
    }
  },
};
</script>
