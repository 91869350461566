<template>
  <footer class="bg-zinc-800 text-white py-8">
    <div class="container mx-auto px-4">
      <div class="grid grid-cols-1 md:grid-cols-5 gap-8">
        <!-- Logo -->
        <div class="flex items-center justify-center md:justify-start">        <router-link to="/">

          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="370.000000pt" height="193.000000pt" viewBox="0 0 370.000000 193.000000"
 preserveAspectRatio="xMidYMid meet" class="h-20 w-full">
<g transform="translate(0.000000,193.000000) scale(0.050000,-0.050000)"
fill="#ffffff" stroke="none">
<path d="M5690 2936 c-504 -96 -890 -651 -811 -1167 124 -808 1108 -1173 1699
-632 l102 93 -90 76 -90 77 -84 -71 c-522 -448 -1342 -26 -1290 663 48 631
778 950 1266 553 129 -106 108 -105 209 -11 l83 76 -106 93 c-260 228 -561
313 -888 250z"/>
<path d="M2261 2466 l3 -476 -422 1 c-232 1 -422 -2 -422 -5 0 -7 368 -501
515 -692 112 -145 101 -160 -119 -160 -303 -1 -448 108 -475 356 l-11 100
-138 6 -137 6 13 -103 c109 -816 1321 -836 1449 -24 21 128 24 125 -130 125
l-127 0 0 -65 c0 -163 -57 -257 -102 -170 -13 25 -81 119 -152 210 l-128 165
326 0 326 0 -4 595 -5 595 -131 6 -132 6 3 -476z"/>
<path d="M2980 1920 l0 -1020 841 0 841 0 -6 122 -6 123 -705 -4 -705 -3 0
901 0 901 -130 0 -130 0 0 -1020z"/>
</g>
</svg>     </router-link>   </div>

        <!-- Categories -->
        <div>
          <h3 class="text-lg font-semibold mb-4">Categories</h3>
          <ul>
            <li v-for="category in categories" :key="category.slug" class="mb-2">
              <router-link :to="`/category/${category.slug}`" class="hover:text-yellow-500">
                {{ category.name }}
              </router-link>
            </li>
          </ul>
        </div>

        <!-- Other Pages -->
        <div>
          <h3 class="text-lg font-semibold mb-4">JLC Central</h3>
          <ul>       
         <li class="mb-2">
              <router-link to="/mission" class="hover:text-yellow-500">Our Mission</router-link>
            </li>
            <li class="mb-2">
              <router-link to="/blog" class="hover:text-yellow-500">Blog</router-link>
            </li>
            <li class="mb-2">
              <router-link to="/faqs" class="hover:text-yellow-500">FAQs</router-link>
            </li>
          </ul>
        </div>

        <!-- Account -->
        <div>
          <h3 class="text-lg font-semibold mb-4">Account</h3>
          <ul v-if="$root.$data.user == null">
            <li class="mb-2">
              <button @click="login" class="hover:text-yellow-500">Login</button>
            </li>
            <li class="mb-2">
             <button @click="signup" class="hover:text-yellow-500">Sign Up</button>
            </li>
          </ul>
          <ul v-else>
            <li class="mb-2">
              <router-link to="/account" class="hover:text-yellow-500">Manage Account</router-link>
            </li>
            <li class="mb-2">
              <button @click="logout" class="hover:text-yellow-500">Logout</button>
            </li>
          </ul>
        </div>

        <!-- Contact -->
        <div>
          <h3 class="text-lg font-semibold mb-4">Contact</h3>
          <p>Email: info@jlcpod.com</p>
          <!-- <p>Phone: (123) 456-7890</p> -->
        </div>
      </div>

      <div class="mt-8 text-center">
        <p>&copy; {{ currentYear }} Julian's Liberty Crusade. All rights reserved.</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      categories: [
        { name: 'Deep Dive', slug: 'Deep%20Dive' },
        { name: 'Special Guest', slug: 'Special%20Guest' },
        { name: 'Shorts', slug: 'Shorts' },
        // Add more categories here as needed
      ],
      currentYear: new Date().getFullYear()
    }
  },
  methods: {
    login() {
      netlifyIdentity.open('login')
    },
    signup() {
      netlifyIdentity.open('signup')
    },
    logout() {
      netlifyIdentity.logout()
    }
  }
}
</script>
