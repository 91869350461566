<template>
  <div>
    <header class="bg-gradient-to-r from-yellow-500 to-amber-600 text-white py-16">
      <div class="container mx-auto px-6 md:px-8 lg:px-16">
        <h1 class="text-4xl font-bold mb-4">Our Blog</h1>
        <p class="text-xl">Stay updated with our latest in the fight for liberty.<br><br>We are JLC Podcast, a libertarian podcast that wants to, in the world of lies, share the truth about what's happening in America.</p>
      </div>
    </header>
    <div class="container mx-auto px-6 md:px-8 lg:px-16 py-8">
      <div v-if="loading" class="text-center">
        <p class="text-xl">Loading blog posts...</p>
      </div>
      <div v-else class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <BlogPostItem 
          v-for="post in sortedPosts" 
          :key="post.id" 
          :post="post" 
          :is-newest="isNewestPost(post)"
          :is-locked="isNewestPost(post) && !isLoggedIn"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import BlogPostItem from '@/components/BlogPostItem.vue';

export default {
  name: 'Blog',
  components: {
    BlogPostItem
  },
  setup() {
    const blogPosts = ref([]);
    const loading = ref(true);
    const isLoggedIn = ref(netlifyIdentity.currentUser() != null);

    const sortedPosts = computed(() => {
      return [...blogPosts.value].sort((a, b) => new Date(b.date) - new Date(a.date));
    });

    const newestPublishedPost = computed(() => {
      const now = new Date();
      return sortedPosts.value.find(post => new Date(post.date) <= now);
    });

    const isNewestPost = (post) => {
      if (!newestPublishedPost.value) return false;
      return post.id === newestPublishedPost.value.id;
    };

    const fetchBlogPosts = async () => {
      try {
        const response = await fetch('/blog_be/posts.json');
        const data = await response.json();
        blogPosts.value = data;
        loading.value = false;
      } catch (error) {
        console.error('Error fetching blog posts:', error);
        loading.value = false;
      }
    };

    onMounted(() => {
      fetchBlogPosts();
    });

    return {
      sortedPosts,
      loading,
      isNewestPost,
      isLoggedIn
    };
  }
}
</script>