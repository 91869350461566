<template>
    <ul class="episode-list grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 px-6 md:px-8 lg:px-16 pt-8episode-list grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 px-6 md:px-8 lg:px-16 pt-8">
        <router-link tag="li" class="bg-white rounded-2xl shadow-md hover:shadow-lg transition-shadow duration-300 overflow-hidden flex flex-col h-full"  v-for="episode in episodes" :key="episode.guid" :to="{ name: 'PodcastEpisodeDetails', params: { id: episode.guid } }">
        <img :src="episode.image.url" :alt="episode.title" class="w-full h-48 object-cover">
        <div class="p-4 flex flex-col flex-grow">
          <h4 class="text-xl font-semibold mb-2">{{ episode.title }}</h4>
          <div class="flex-grow flex items-center">
            <p class="text-gray-600">{{ truncateDescription(episode.description) }}</p>
          </div>
          <!-- <audio :src="episode.enclosures[0].url" controls class="w-full mt-4"></audio> -->
        </div>
      </router-link>
    </ul>
  </template>
  
  <script>
  export default {
    name: 'EpisodeList',
    props: {
      episodes: {
        type: Array,
        required: true
      }
    },
    methods: {
      truncateDescription(description) {
        if (description.length <= 100) {
          return description;
        }
        return description.slice(0, 100).trim() + '...';
      }
    }
  }
  </script>
