<template>
    <div class="container mx-auto my-12 px-6" v-if="$root.$data.user !== null">
        <h1 class="text-4xl font-bold">Account Settings</h1>
        <div class="mt-8 grid gap-6">
            <div class="bg-white p-4 rounded-2xl shadow-md">
                <h2 class="text-2xl font-bold mb-4">Current Account Information</h2>
                <div v-if="$root.$data.user !== null">
                    <p class="mb-2"><strong>Name:</strong> {{ $root.$data.user.user_metadata.full_name }}</p>
                    <p class="mb-2"><strong>Email:</strong> {{ $root.$data.user.email.toLowerCase() }}</p>
                    <p class="mb-2"><strong>Plan:</strong> <span class="px-2 bg-yellow-500 rounded-2xl text-white">{{
                        ($root.$data.user.user_metadata.plan || "free").toUpperCase() }}</span></p>
                    <p><strong>Valued Member Since:</strong> {{ new
                        Date($root.$data.user.created_at).toLocaleDateString('en-US', {
                            month: 'long', day: 'numeric',
                            year: 'numeric'
                        })
                        }}</p>
                    <div v-if="$root.$data.user.user_metadata.plan && $root.$data.user.user_metadata.plan !== 'free'">
                        <p><strong>Plan renews on:</strong> **** ADD RENEWAL DATE HERE ****</p>
                    </div>
                    <button @click="changePlan" class="mt-4 px-4 py-2 bg-yellow-500 text-white rounded-2xl">Change
                        Plan</button>
                </div>
                <div v-else>
                    <p>Loading user information...</p>
                </div>
            </div>
            <div class="bg-white p-4 rounded-2xl shadow-md">
                <h2 class="text-2xl font-bold">Change Name</h2>
                <form @submit.prevent="changeName">
                    <div class="mt-4">
                        <label for="newName" class="block text-sm font-medium text-gray-700">New Name</label>
                        <input type="text" id="newName" v-model="newName"
                            :placeholder="`${$root.$data.user.user_metadata.full_name}`"
                            class="mt-1 block w-full p-2 border border-gray-300 rounded-md" required />
                    </div>
                    <button type="submit" class="mt-4 px-4 py-2 bg-yellow-500 text-white rounded-2xl">Change
                        Name</button>
                </form>
            </div>
            <div class="bg-white p-4 rounded-2xl shadow-md">
                <h2 class="text-2xl font-bold">Change Email</h2>
                <form @submit.prevent="changeEmail">
                    <div class="mt-4">
                        <label for="newEmail" class="block text-sm font-medium text-gray-700">New Email</label>
                        <input type="email" id="newEmail" v-model="newEmail"
                            :placeholder="`${$root.$data.user.email.toLowerCase()}`"
                            class="mt-1 block w-full p-2 border border-gray-300 rounded-md" required />
                    </div>
                    <button type="submit" class="mt-4 px-4 py-2 bg-yellow-500 text-white rounded-2xl">Change
                        Email</button>
                </form>
            </div>
            <div class="bg-white p-4 rounded-2xl shadow-md">
                <h2 class="text-2xl font-bold">Change Password</h2>
                <form @submit.prevent="changePassword">
                    <div class="mt-4">
                        <label for="newPassword" class="block text-sm font-medium text-gray-700">New Password</label>
                        <input type="password" id="newPassword" v-model="newPassword"
                            class="mt-1 block w-full p-2 border border-gray-300 rounded-md" required />
                    </div>
                    <div class="mt-4">
                        <label for="confirmPassword" class="block text-sm font-medium text-gray-700">Confirm
                            Password</label>
                        <input type="password" id="confirmPassword" v-model="confirmPassword"
                            class="mt-1 block w-full p-2 border border-gray-300 rounded-md" required />
                    </div>
                    <button type="submit" class="mt-4 px-4 py-2 bg-yellow-500 text-white rounded-2xl">Change
                        Password</button>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Account",
    data() {
        return {
            newPassword: "",
            confirmPassword: "",
            newEmail: ""
        }
    },
    mounted() {
        if (this.$root.$data.user == null) {
            netlifyIdentity.open("login");
            this.$router.push("/")
        }
    },
    methods: {
        logout() {
            netlifyIdentity.logout()
        },
        changePlan() {
            console.log("Change plan clicked")
            swal({
                title: "Coming Soon",
                icon: "info",
                button: "Continue",
            })
        },
        changePassword() {
            if (this.$data.newPassword !== this.$data.confirmPassword) {
                swal({
                    title: "Passwords do not match",
                    icon: "error",
                    button: "Try Again",
                }).then(() => {
                    this.$data.newPassword = ""
                    this.$data.confirmPassword = ""
                })
                return
            } else {
                netlifyIdentity.currentUser().update({ password: this.$data.newPassword }).then(user => {
                    this.$root.$data.user = user;
                    console.log("Password updated successfully")
                    swal({
                        title: "Password updated successfully!",
                        icon: "success",
                        button: "Logout Now",
                    }).then(() => {
                        netlifyIdentity.logout()
                        netlifyIdentity.open("login")
                        this.$router.push("/")
                    })
                }).catch(err => {
                    console.log("Error updating password", err)
                    swal({
                        title: "Error updating password",
                        icon: "error",
                        button: "Try Again",
                    }).then(() => {
                        this.$data.newPassword = ""
                        this.$data.confirmPassword = ""
                    })
                })
            }
        },
        changeEmail() {
            if (!this.$data.newEmail || !this.$data.newEmail.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
                swal({
                    title: "Please enter a valid email",
                    icon: "error",
                    button: "Try Again",
                }).then(() => {
                    this.$data.newEmail = ""
                })
                return
            }
            netlifyIdentity.currentUser().update({ email: this.$data.newEmail }).then(user => {
                this.$root.$data.user = user;
                console.log("Email updated successfully")
                swal({
                    title: "Email updated successfully! Check your email for verification.",
                    icon: "success",
                    button: "Logout Now",
                }).then(() => {
                    netlifyIdentity.logout()
                    netlifyIdentity.open("login")
                    this.$router.push("/")
                })
            }).catch(err => {
                console.log("Error updating email", err)
                swal({
                    title: "Error updating email",
                    icon: "error",
                    button: "Try Again",
                }).then(() => {
                    this.$data.newEmail = ""
                })
            })
        },
        changeName() {
            netlifyIdentity.currentUser().update({ user_metadata: { full_name: this.$data.newName } }).then(user => {
                this.$root.$data.user = user;
                console.log("Name updated successfully")
                swal({
                    title: "Name updated successfully!",
                    icon: "success",
                    button: "Continue",
                }).then(() => {
                    window.location.reload()
                })
            }).catch(err => {
                console.log("Error updating name", err)
                swal({
                    title: "Error updating name",
                    icon: "error",
                    button: "Try Again",
                }).then(() => {
                    this.$data.newName = ""
                })
            })
        }
    }
}
</script>